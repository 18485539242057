<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
// import { useHead } from '@unhead/vue'
import '~/assets/scss/app.scss'

// useHead({
//   title: import.meta.env.VITE_APP_TITLE,
//   meta: [
//     { name: 'description', content: import.meta.env.VITE_APP_DESCRIPTION },
//     { name: 'keywords', content: import.meta.env.VITE_APP_KEYWORDS },
//     { name: 'og:title', content: import.meta.env.VITE_APP_TITLE },
//     { name: 'og:description', content: import.meta.env.VITE_APP_DESCRIPTION },
//     { name: 'og:type', content: 'website' },
//     { name: 'og:image', content: '/img/logo.svg' },
//     { name: 'twitter:title', content: import.meta.env.VITE_APP_TITLE },
//     { name: 'twitter:creator', content: '@JPoolSolana' },
//   ],
//   link: [
//     {
//       rel: 'icon',
//       type: 'image/svg+xml',
//       href: '/img/favicon.svg',
//     },
//   ],
// })

const auth = useAuthStore()
const isClient = !import.meta.env.SSR
const isPasswordProtected = computed(() => auth.isEnabled && !auth.isAuthenticated && isClient)
const { initStorageVersion } = useStorageVersion()

onBeforeMount(() => {
  initWallet()
  initStorageVersion()
})
</script>

<template>
  <password-protect v-if="isPasswordProtected" />
  <router-view v-else />
</template>
